export const environment = {
    production: true,
    apiRootUrl: 'https://agent-api-staging.remedyoncall.com/',
    appVersion: 1,
    iframeSrc: 'https://boltgw-uat.cardconnect.com/itoke/ajax-tokenizer.html',

  firebase: {
    apiKey: "AIzaSyDooOMSezCoiNefEyJN14UwZbYi1cT4uUg",
    authDomain: "remedy-staging-8e7cf.firebaseapp.com",
    databaseURL: "https://remedy-staging-8e7cf.firebaseio.com",
    projectId: "remedy-staging-8e7cf",
    storageBucket: "remedy-staging-8e7cf.appspot.com",
    messagingSenderId: "21428748039",
    appId: "1:21428748039:web:8038609f53c3bdcb990fa2",
    measurementId: "G-1WDHYYB17P"
  }
  
    
  };